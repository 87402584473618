import type { SafeEventEmitterProvider } from '@web3auth/base';
import { ethers } from 'ethers';
import Web3 from 'web3';

export default class EthereumRpc {
  private provider: SafeEventEmitterProvider;

  constructor(provider: SafeEventEmitterProvider) {
    this.provider = provider;
  }
  async getAccounts(): Promise<string[]> {
    try {
      const web3 = new Web3(this.provider as any);
      const accounts = await web3.eth.getAccounts();
      return accounts;
    } catch (error: unknown) {
      console.error((error as Error).message);
      throw error;
    }
  }

  async signMessageL1(msg) {
    // await window.ethereum.request({ method: 'eth_requestAccounts' });
    const provider = new ethers.providers.Web3Provider(this.provider);
    const signer = provider.getSigner();

    let signed_message = await signer.signMessage(msg);

    return signed_message;
  }
}
