import { ADAPTER_EVENTS, CHAIN_NAMESPACES } from '@web3auth/base';
import { Web3Auth } from '@web3auth/web3auth';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { useEffect, useState } from 'react';
import RPC from '../connectors/web3Auth/config/evm';

const clientId = process.env.REACT_APP_WEB3_AUTH_CLIENT_ID; // get from https://dashboard.web3auth.io

function useWeb3Auth() {
  const [web3auth, setWeb3auth] = useState(null);
  const [provider, setProvider] = useState(null);

  const init = async () => {
    try {
      const initParams = {};
      const web3AuthCtorParams = {
        clientId,
        chainConfig: {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: '0x5',
          rpcTarget:
            'https://goerli.infura.io/v3/8ece73f9e9cb435ea360c8cca041bbf6', // This is the testnet RPC we have added, please pass on your own endpoint while creating an app
        },
      };
      const web3authInstance = new Web3Auth(web3AuthCtorParams);
      const openloginAdapter = new OpenloginAdapter({
        adapterSettings: {
          clientId,
          network: 'testnet',
          uxMode: 'redirect',
          whiteLabel: {
            name: 'Brine',
            dark: true, // whether to enable dark mode. defaultValue: false
          },
        },
      });
      web3authInstance.configureAdapter(openloginAdapter);
      subscribeAuthEvents(web3authInstance);
      setWeb3auth(web3authInstance);
      await web3authInstance.initModal(initParams);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!web3auth) {
      init();
    }
  }, [web3auth]);

  const subscribeAuthEvents = (web3auth) => {
    // Can subscribe to all ADAPTER_EVENTS and LOGIN_MODAL_EVENTS
    web3auth.on(ADAPTER_EVENTS.CONNECTED, (data) => {
      console.log('Yeah!, you are successfully logged in', data);
      if (provider) {
      } else if (web3auth?.provider !== null) {
      }
      setProvider(provider);
    });

    web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
      console.log('connecting');
      if (provider) {
      } else if (web3auth?.provider !== null) {
      }
      setProvider(web3auth?.provider);
    });

    web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
      console.log('disconnected');
    });

    web3auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
      console.error('some error or user has cancelled login request', error);
    });
  };

  const login = async () => {
    if (!web3auth) {
      console.log('web3auth not initialized yet');
      return;
    }
    const providerRes = await web3auth.connect();
    setProvider(providerRes);
  };

  const logout = async () => {
    if (!web3auth) {
      console.log('web3auth not initialized yet');
      return;
    }
    await web3auth.logout();
    setProvider(null);
  };

  const signMessage = async (msg) => {
    if (!provider) {
      console.log('provider not initialized yet');
      return;
    }
    const rpc = new RPC(provider);
    const result = await rpc.signMessageL1(msg);
    console.log(result);
    console.log('here1');
    return result;
  };

  return {
    login,
    logout,
    signMessage,
    web3auth,
    provider,
  };
}

export default useWeb3Auth;
