import { useEffect, useState } from 'react';
import useWeb3Auth from '../../utils/hooks/useWeb3Auth';
import icon from '../../assets/security.png';
const CryptoJS = require('crypto-js');

const encrypt = (text) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
};

const decrypt = (data) => {
  return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
};

function Webview() {
  const { login, web3auth, signMessage, logout } = useWeb3Auth();
  const [confirm, setConfirm] = useState(false);

  const handleLogin = async () => {
    try {
      await login();
      let hash_msg = await signMessage(
        "Click sign to verify you're a human - Brine.finance"
      );
      let obj = {
        type: 'success',
        key: hash_msg,
      };
      setConfirm(true);
      if (hash_msg) {
        let msg = encrypt(JSON.stringify(obj));
        window.ReactNativeWebView.postMessage(msg);
        // logout();
      }
    } catch (error) {
      let obj = {
        type: 'failed',
        key: '',
      };
      let msg = encrypt(JSON.stringify(obj));
      window.ReactNativeWebView.postMessage(msg);
      console.log(error);
    }
  };

  useEffect(() => {
    if (web3auth && confirm) {
      setTimeout(() => {
        handleLogin();
      }, 3000);
    }
  }, [web3auth, login, confirm]);

  useEffect(() => {
    if (web3auth?.status === 'connected') {
      setConfirm(true);
    }
  }, [web3auth]);

  console.log({ web3auth: web3auth?.status });

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      {!confirm ? (
        <div class='banner'>
          <img src={icon} />
          <h1>
            Before proceeding please make sure you’re being redirected from the
            Official Brine Mobile App
          </h1>
          <button onClick={() => setConfirm(true)}>
            I confirm I came from Brine Mobile App
          </button>
        </div>
      ) : (
        <div class='loader'></div>
      )}
    </div>
  );
}

export default Webview;
